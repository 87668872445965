const bodyWrap = document.querySelector("body");
const menuToggle = document.querySelector(".site-menu-toggle");
const searchToggle = document.querySelector(".search-toggle");
const searchWrap = document.querySelector(".search-overlay");
const filterToggle = document.querySelector(".filter-mobile-toggle");
const filterWrap = document.querySelector(".filter-wrap ");
const closeFilter = document.querySelector(".close-filter");
const closeMenu = document.querySelector(".close-menu");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const backTop = document.querySelector(".back-to-top");
const counterItem = document.querySelectorAll(".counter");
const setBackground = document.querySelectorAll("[setBackground]");
const cartToggle = document.querySelector(".shopping-cart-toggle");
const cartWrap = document.querySelector(".shopping-cart-wrap");
const sideNav = document.querySelector(".sidenav-group");

document.addEventListener("DOMContentLoaded", () => {
	let width = this.window.innerWidth;
	mappingFunc();
	swiperInit();
	generalFunction.init();
	// priceFilterInit();
	stickyNav();
	if (width <= 1024.1) {
		toggleMobileNav();
	}
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		hideElement();
	}
	if (width <= 1024.1) {
		toggleMobileNav();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;

	const scrollTop = window.scrollY || document.documentElement.scrollTop;

	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}

	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (cartToggle !== null) {
		if (
			!cartToggle.contains(event.target) &&
			!cartWrap.contains(event.target)
		) {
			cartWrap.classList.remove("open");
		}
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (filterToggle !== null) {
		if (!event.target.closest(".filter-mobile-toggle, .filter-wrap ")) {
			filterWrap.classList.remove("is-open");
		}
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});
function toggleMobileNav() {
	$(".side-nav-toggle").on("click", function () {
		$(this).next().slideToggle();
	});
	$(".sticky-nav .title").on("click", function () {
		$(this).next().slideToggle();
	});
}

function stickyNav() {
	$(window)
		.scroll(function () {
			var scrollDistance = $(window).scrollTop();
			// Assign active class to nav links while scolling
			$(".link-to-sector .sector-id").each(function (i) {
				if (
					$(this).position().top - $("header").outerHeight() - 1 <=
					scrollDistance
				) {
					$(".sticky-nav li.active").removeClass("active");
					$(".sticky-nav li").eq(i).addClass("active");
				}
			});
		})
		.scroll();
	$(".sticky-nav a").on("click", function (event) {
		if (this.hash !== "") {
			let offset = $("header").outerHeight();
			var hash = this.hash;
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - offset,
				},
				800,
				function () {
					// window.location.hash = hash;
				}
			);
		} // End if
	});
}

// function addCommas(nStr) {
// 	nStr += "";
// 	x = nStr.split(".");
// 	x1 = x[0];
// 	x2 = x.length > 1 ? "." + x[1] : "";
// 	var rgx = /(\d+)(\d{3})/;
// 	while (rgx.test(x1)) {
// 		x1 = x1.replace(rgx, "$1" + "." + "$2");
// 	}
// 	return x1 + x2;
// }
// function priceFilterInit() {
// 	/********Price filter ********/
// 	minprice = 0;
// 	maxprice = 20000000;
// 	initSlider();

// 	function initSlider() {
// 		if ($(".price-slider").length > 0) {
// 			minValue = 0;
// 			maxValue = 20000000;
// 			if ($(".min-price").text().length)
// 				minValue = parseInt($(".min-price").text());
// 			if ($(".max-price").text().length)
// 				maxValue = parseInt($(".max-price").text());

// 			var options = {
// 				range: true,
// 				step: 200000,
// 				min: 0,
// 				max: 20000000,
// 				values: [minValue, maxValue],
// 				slide: function (event, ui) {
// 					$(".min-price").html(addCommas(ui.values[0]) + " đ");
// 					$(".max-price").html(addCommas(ui.values[1]) + " đ");
// 				},
// 				change: function (event, ui) {
// 					if (minprice == ui.values[0] && maxprice == ui.values[1]) {
// 						// do nothing
// 					} else {
// 						minprice = ui.values[0];
// 						maxprice = ui.values[1];
// 						priceChange();
// 					}
// 				},
// 			};

// 			$(".price-slider").slider(options);

// 			$(".min-price").html(
// 				addCommas($(".price-slider").slider("values", 0) + " đ")
// 			);
// 			$(".max-price").html(
// 				addCommas($(".price-slider").slider("values", 1) + " đ")
// 			);
// 		}
// 	}
// }

const generalFunction = {
	toggleFunc: () => {
		menuToggle.addEventListener("click", () => {
			menuMobile.classList.toggle("is-open");
			hamburger.classList.toggle("is-active");
			bodyWrap.classList.toggle("overlay-bg");
		});
		closeMenu.addEventListener("click", () => {
			menuMobile.classList.remove("is-open");
			hamburger.classList.remove("is-active");
			bodyWrap.classList.remove("overlay-bg");
		});
		searchToggle.addEventListener("click", () => {
			searchWrap.classList.toggle("is-open");
			bodyWrap.classList.toggle("overlay-bg");
		});

		if (filterToggle !== null) {
			filterToggle.addEventListener("click", () => {
				filterWrap.classList.toggle("is-open");
				bodyWrap.classList.toggle("overlay-bg");
			});
			closeFilter.addEventListener("click", () => {
				filterWrap.classList.remove("is-open");
				bodyWrap.classList.remove("overlay-bg");
			});
		}

		$(".toggle-wrap").each(function (index, element) {
			let $this = $(this);
			$this.addClass("toggle-instance-" + index);
			$(".toggle-instance-" + index + " .toggle-item").click(function () {
				if ($(this).hasClass("is-toggle")) {
					$(this).find(".article").slideUp();
					$(this).removeClass("is-toggle");
				} else {
					$(this).find(".article").slideDown();
					$(this).addClass("is-toggle");
				}
			});
		});
	},
	backTop: () => {
		backTop.addEventListener("click", (event) => {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, "300");
		});
	},
	tabFunction: () => {
		$(".tab-nav a").on("click", function () {
			$(this).parents(".tab-nav").find("li").removeClass("active");
			$(this).parents("li").addClass("active");

			var tab = $(this).attr("data-type");
			$(this).parents("section").find(".tab-item").removeClass("active");
			$(this)
				.parents("section")
				.find("#" + tab)
				.addClass("active");
		});
	},
	dropdownMenu: () => {
		if ($(window).width() < 1200.1) {
			$(".drop-down .title em").on("click", function () {
				if ($(this).parents(".drop-down").hasClass("is-open")) {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
				} else {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
					$(this).parent().next().slideDown();
					$(this).parents(".drop-down").addClass("is-open");
				}
			});
			$(".tertiary-menu-toggle").on("click", function () {
				$(".nav-tertiary-menu").slideToggle();
			});
		}
	},

	counterAnimate: () => {
		if (counterItem !== null) {
			if (counterItem.length) {
				const counterUp = window.counterUp.default;
				const callback = (entries) => {
					entries.forEach((entry) => {
						const el = entry.target;
						if (
							entry.isIntersecting &&
							!el.classList.contains("is-visible")
						) {
							counterUp(el, {
								duration: 2000,
								delay: 200,
							});
							el.classList.add("is-visible");
						}
					});
				};
				const IO = new IntersectionObserver(callback, { threshold: 1 });

				counterItem.forEach((counter) => {
					IO.observe(counter);
				});
			}
		}
	},
	setBackground: () => {
		setBackground.forEach((attributes) => {
			attributes.style.cssText = `
			background-image: url(${attributes.getAttribute("setBackground")});
			background-size: cover;
			background-position: center;
		  `;
		});
	},

	
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			const [search, menu, dealer] = [
				".searchbox",
				".nav-primary-menu",
				".header-dealer-wrap",
			].map((selector) => document.querySelector(selector));

			[search, menu, dealer].forEach((el) => (el.style.display = "flex"));
		}
	},
	expandContent: () => {
		$(".read-more-wrap").each(function (index) {
			$(this).addClass("read-instance-" + index);
			let $this = $(".read-instance-" + index);
			$this.each(function () {
				let height = $this.find(".article").outerHeight();
				console.log(height);
				if (height < 300) {
					$this.find(".btn-read-more").addClass("hide");
					console.log("hide");
				} else {
					$this.find(".article").css({
						height: "300",
					});
					console.log("limit");
				}
				let button = $this.find(".btn-read-more");
				let content = $this;
				button.on("click", function () {
					content.toggleClass("active");
				});
			});
		});
	},
	appendHTML: () => {
		if ($("body").hasClass("product-detail-page")) {
			if ($(".commentpanel").length) {
				$(".commentpanel").appendTo(".product-rating-wrap");
			}
			if ($(".tour-recently-view").length) {
				$(".tour-recently-view").appendTo(".product-recently");
			}
		}
	},
	init: () => {
		generalFunction.toggleFunc();
		generalFunction.backTop();
		generalFunction.tabFunction();
		generalFunction.dropdownMenu();
		generalFunction.counterAnimate();
		generalFunction.setBackground();
		generalFunction.hideElement();
		generalFunction.expandContent();
		generalFunction.appendHTML();
	},
};

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		slidesPerView: 1,
		rewind: true,
		speed: 1205,
		autoplay: {
			delay: 4000,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	$(".init-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-init-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-nav-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-nav-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-inst-" + index);

		var swiper = new Swiper(".swiper-init-" + index, {
			speed: 1200,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: false,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 0,
			observeParents: true,
			slidesPerView: "auto",
			preventInteractionOnTransition: true,
			navigation: {
				nextEl: ".next-nav-" + index,
				prevEl: ".prev-nav-" + index,
			},
			pagination: {
				el: ".pagination-inst-" + index,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
			},
		});
	});

	$(".swiper-grid .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("grid-inst-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-grid-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-grid-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-grid-inst-" + index);

		var swiper = new Swiper(".grid-inst-" + index, {
			autoplay: {
				delay: 4000,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 10,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				460: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 30,
					grid: {
						rows: 2,
						fill: "row",
					},
				},
			},

			navigation: {
				nextEl: ".next-grid-" + index,
				prevEl: ".prev-grid-" + index,
			},
			pagination: {
				el: ".pagination-grid-inst-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	var commentBG = new Swiper(".swiper-cmt-bg .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		speed: 800,
		autoplay: {
			delay: 2000,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
	});
	var commentTXT = new Swiper(".swiper-cmt-txt .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		speed: 800,
		autoplay: {
			delay: 2000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-cmt-txt .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var commentIMG = new Swiper(".swiper-cmt-image .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		speed: 800,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
	});
	commentBG.on("slideChange", function () {
		commentTXT.slideTo(commentBG.activeIndex);
		commentIMG.slideTo(commentBG.activeIndex);
	});
	commentTXT.on("slideChange", function () {
		commentBG.slideTo(commentTXT.activeIndex);
		commentIMG.slideTo(commentTXT.activeIndex);
	});
	commentIMG.on("slideChange", function () {
		commentTXT.slideTo(commentIMG.activeIndex);
		commentBG.slideTo(commentIMG.activeIndex);
	});
	var productThumbs = new Swiper(".swiper-product-thumb  .swiper", {
		spaceBetween: 8,
		breakpointsInverse: true,
		breakpoints: {
			280: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 4,
			},
			768.1: {
				spaceBetween: 15,
				slidesPerView: 5,
			},
			1024.1: {
				spaceBetween: 10,
				slidesPerView: 5,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		spaceBetween: 15,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".swiper-product-main  .nav-next",
			prevEl: ".swiper-product-main  .nav-prev",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});
}

function mappingFunc() {
	new MappingListener({
		selector: ".search-overlay",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-nav-brand",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".header-dealer-wrap",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
